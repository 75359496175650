$themes: (
  red : ($color-red, $color-red),  
  orange : ($color-orange, $color-orange),
  yellow : ($color-yellow, $color-yellow),
  green : ($color-green, $color-green),
  aqua : ($color-aqua, $color-aqua),
  blue : ($color-blue, $color-blue),
  purple : ($color-purple, $color-purple),
  violet : ($color-violet, $color-violet)
);
@each $theme-code, $params in $themes{
    $color: nth($params, 1);
    //$dkColor : nth($params, 2);
    //$color2: nth($params, 2);
    div.body-content.theme-color-#{$theme-code}{
        .cms_item.htmlPanel{ 
            h1, h2, h3, h4{
                b, strong{
                    color: $color;
                }
            }
            h1, h2, h3, h4{
                span strong {
                  color: inherit;
                }
            } 
        }
        div.call-in-menu a{
            background-color: $color;
        }
    }
}