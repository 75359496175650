@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";
@import "./modules/accordingPanel";
@import "./modules/rotatingReviewsPanel";
@import "./modules/fullPanelGoogleMap";
@import "./modules/youtubeNew";

// components
@import "../components";

@import "./site/index";

//aos
@import "~aos/src/sass/aos";

//Theme
@import "./theme";

@media print {
  @import "./print";
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

// body{
//   background-color: black;
// }

.form-control::placeholder {
  font-weight: $weight-normal;
  //color: red;
}

// ::placeholder {
//   color: red;
// }

// div.file__attach__text{
//   position: absolute;
//   width: auto;
//   top: 8%;
//   left: 37%;
//   font-weight: 500;
//   color: white;

//   @include media-breakpoint-up(sm) { // 576px and up
//     left: 112px;
//   }
//   @include media-breakpoint-up(lg){
//     top: 4%;
//     left: 17%;
//   }
// }

input.form-control-file {
  color: transparent;
  font-family: $family-button;
}

div.form-check {
  @include media-breakpoint-down(xs) {
    margin-bottom: 0.5rem;
  }
}

input.form-check-input {
  //margin-top: 0.35rem;
  margin-top: 0rem;
  position: absolute;
  top: 44%;
  transform: translateY(-50%);

  @include media-breakpoint-down(xs) {
    width: 16px;
    height: 16px;
  }
}

.form-control {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

div.call-in-menu {
  margin-top: 0.5rem;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0.5rem;
  a {
    background-color: #999;
    width: 100%;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
    //border: 1px solid #999;
    border-radius: 0.25rem;
    display: block;
    position: relative;
    color: white;
    font-weight: 400;
    &:hover {
      text-decoration: none;
    }
    span.call-us-text {
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1;
      //padding-top: 4px;
      font-size: 1.05rem;
      font-family: $family-button;
      font-weight: 800;
    }
    svg {
      display: block;
      font-size: 1.5rem;
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

body.safari-agent {
  input.form-check-input {
    //margin-top: 0.35rem;
    margin-top: 0rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

// form.searchBar input.searchBar__input{
//   &::placeholder{
//     color: red!important;
//   }
// }

// ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
//   color: red !important;
//   opacity: 1; /* Firefox */
// }

.multiColumnPanelv2 .new__multicolumn__panel__html h3,
.multiColumnPanelv2 .new__multicolumn__panel__html h4,
.multiColumnPanelv2 .new__multicolumn__panel__html p {
  @media screen and (max-width: 576px) {
    text-align: left !important;
  }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-right: 2rem;
  padding-left: 2rem;
}

.container.abcccd {
  // maxWidth: ["100%"],
  //   paddingLeft: "0px",
  //   paddingRight: "0px",
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}