// overriding bootstrap variables in _boostrap-override.scss

$family-base: "fira-sans-condensed",sans-serif;
$family-header: "fira-sans-condensed",serif;
$family-button: "proxima-nova",serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-normal: 300;
$weight-bold: 400;

// colors - sister colours from bootrap

$color-red:     #ED1C24;
$color-orange:  #F15A29;
$color-yellow:   #F7941D;
$color-green:   #39B54A;
$color-aqua:   #00A79D;
$color-blue:    #0A7CC9;
$color-purple:    #6832AD;
$color-violet:    #92278F;

// more colors
$color-gold:  #faa222;
$color-dark-gray: #444444;
$color-dark-gray2: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-red;
$font-color: $color-dark-gray;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color:black;
$link-color: $primary-color;
$link-hover-color: darken($primary-color, 10);



// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (mobile: 44px, small-device:56px, tablet:56px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;