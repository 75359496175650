@import "../_scss/modules/bannerSize/full.scss";
@import "../_scss/modules/bannerSize/standard-video.scss";
.youtubePlayer {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.youtubePlayer__iframe {
  z-index: 1;
  max-width: none;
  transition: opacity 1.5s;
  opacity: 0;
}

.youtubePlayer__poster {
  @include bg-img();
  z-index: 2;
  transition: opacity 1.5s;
  opacity: 1;
}

.youtubePlayer__mask {
  //background-color: rgba(#000, 0.2);
  z-index: 3;
}

.youtubePlayer .youtubePlayer__soundIcon {
  z-index: 10;
  position: absolute;
  right: 13px;
  bottom: 3%;
  width: 40px;
  height: 40px;
  top: auto;
  left: auto;
  color: white;
  border: white solid 2px;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.7 ease;
  display: flex;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
  &:hover {
    color: $primary-color;
    border-color: $primary-color;
  }
  @media screen and (min-width: 576px) {
    right: 50px;
    bottom: 12%;
    width: 60px;
    height: 60px;
  }
}

.youtubePlayer__playIcon {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  color: white;
  transition: all 0.7 ease;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: $primary-color;
    border-color: $primary-color;
  }
}

.youtubePlayer--canPlay.youtubePlayer--loaded {
  .youtubePlayer__iframe {
    opacity: 1;
  }

  .youtubePlayer__poster {
    opacity: 0;
  }
}
.youtubePlayer--thin {
  @extend %bannerSize--thin;
  // @include media-breakpoint-up(xl) {
  //   height: calc(100vh - 2rem);
  // }
}
.youtubePlayer--full {
  @extend %bannerSize--full;
  @include media-breakpoint-up(xl) {
    // 1200px and up
    height: calc(100vh - 2rem);
  }
}

.youtubePlayer--standard {
  @extend %bannerVideoSize--standard;
  @media screen and (max-width: 1200px) {
    height: 664px !important;
  }
  @media screen and (max-width: 768px) {
    height: 418px !important;
  }
  @media screen and (max-width: 576px) {
    height: 312px !important;
  }
  @media screen and (max-width: 400px) {
    height: 218px !important;
  }
}

.youtubePlayer--thin {
  @extend %bannerSize--thin;
}

.modal-video-close-btn {
  right: 0;
}

.videoPanelWithText .container {
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  padding-left: 0;
  padding-right: 0;
}
.videoPanelWithText .video_panel_container {
  position: relative;
}
.videoPanelWithText .htmlPanel {
  position: absolute;
  width: 90vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 998;
}

.videoPanelWithText img {
  max-width: 100%;
  display: block;
  margin-inline: auto;

  @media screen and (max-width: 576px) {
    width: 70%;
    height: 70%;
  }
}

.videoPanelWithText .htmlPanel h1 {
  @media screen and (max-width: 400px) {
    font-size: 1.7rem;
  }
}
.videoPanelWithText iframe {
  width: 100%;
}
.videoPanelWithText .htmlPanel a.button {
  display: inline-block;
  color: black;
  background: #fff;
  border: 1px solid #000;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover {
    background: $primary-color;
    color: #fff;
    text-decoration: none;
  }
}

.youtubePlayerNew2 .youtubePlayer__iframe {
  opacity: 1;
}
.youtubePlayerNew2.youtubePlayer--standard {
  aspect-ratio: 16 / 9;
  height: auto;
}
