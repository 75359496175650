.invalid {
  font-size: 85%;
  color: red;
}

// overriding bootrap invalid text box (remove x icon)
.was-validated .form-control:invalid,
.form-control.is-invalid {
  //background: none;
  padding-right: 0.75rem;
}

.editor {
  max-width: 1600px;

  .label {
    margin-bottom: 0.1rem;
    font-size: 0.75rem;
    color: #999;
  }

  .form-group {
    margin-bottom: 0.3rem;
  }

  .actions {
    margin: 1.5rem 0;

    button,
    a {
      margin-right: 0.9rem;
    }
  }
}

.htmlEditor {
  textarea.form-control {
    max-height: none;
    min-height: 300px;
  }

  &__panel {
    display: none;

    &.active {
      display: block;
    }
  }

  &__view {
    margin-top: 3px;
    text-align: right;

    button {
      font-size: 0.75rem;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: #efefef;
    margin-bottom: 0px !important;
    font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    color: #444;
    border: 1px solid #444;
  }
  div.file__attach__text {
    margin-left: 1rem;
    color: #444;
  }
}

@import "steps";
