.mapSize_standard iframe {
  width: 100%;
  height: 190px;
  @media screen and (min-width: 576px) {
    height: 400px;
  }
}
.mapSize_thin iframe {
  width: 100%;
  height: 150px;
  @media screen and (min-width: 576px) {
    height: 300px;
  }
}
.mapSize_large iframe {
  width: 100%;
  height: 250px;
  @media screen and (min-width: 576px) {
    height: 600px;
  }
}
