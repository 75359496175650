.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  margin-left: -15px;
  margin-right: -15px;

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
      height: 100%;
    }
  }
}

.pageTile__content {
  .tile__bg {
    padding-top: 65%;
    position: relative;
  }
  .tile__line {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .tile__line__plus {
    position: absolute;
    bottom: 97%;
    right: 0px;
    width: 18%;
    img {
      width: 100%;
    }
  }

  path {
    fill: white;
  }

  .pageTile_read_more {
    border: 1px solid transparent !important;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    // .pageTile_details {
    //   background: $color-orange;
    // }

    .pageTile__line__style {
      border-top-color: white;
    }

    &.red {
      .pageTile_details {
        background: $color-red;
      }
      path {
        fill: $color-red;
      }
      .pageTile_read_more {
        background: $color-red !important;
        border: 1px solid white !important;
      }
    }
    &.orange {
      .pageTile_details {
        background: $color-orange;
      }
      path {
        fill: $color-orange;
      }
      .pageTile_read_more {
        background: $color-orange !important;
        border: 1px solid white !important;
      }
    }
    &.yellow {
      .pageTile_details {
        background: $color-yellow;
      }
      path {
        fill: $color-yellow;
      }
      .pageTile_read_more {
        background: $color-yellow !important;
        border: 1px solid white !important;
      }
    }
    &.green {
      .pageTile_details {
        background: $color-green;
      }
      path {
        fill: $color-green;
      }
      .pageTile_read_more {
        background: $color-green !important;
        border: 1px solid white !important;
      }
    }
    &.aqua {
      .pageTile_details {
        background: $color-aqua;
      }
      path {
        fill: $color-aqua;
      }
      .pageTile_read_more {
        background: $color-aqua !important;
        border: 1px solid white !important;
      }
    }
    &.blue {
      .pageTile_details {
        background: $color-blue;
      }
      path {
        fill: $color-blue;
      }
      .pageTile_read_more {
        background: $color-blue !important;
        border: 1px solid white !important;
      }
    }
    &.purple {
      .pageTile_details {
        background: $color-purple;
      }
      path {
        fill: $color-purple;
      }
      .pageTile_read_more {
        background: $color-purple !important;
        border: 1px solid white !important;
      }
    }
    &.violet {
      .pageTile_details {
        background: $color-violet;
      }
      path {
        fill: $color-violet;
      }
      .pageTile_read_more {
        background: $color-violet !important;
        border: 1px solid white !important;
      }
    }

    .pageTile__title,
    .pageTile_desc {
      color: white !important;
    }
    .tile__bg {
      //transform: scale(1.02);
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1;
  margin: 0.5rem 0;
  margin-bottom: 0px;
  padding-bottom: 0.5rem;
  color: black;
  //text-align: center;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.8rem;
  color: $color-dark-gray2 !important;
  //text-align: center;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  //margin-top: 0.6rem;
}

.pageTile_read_more {
  display: inline-block;
  //text-align: center;
  background-color: blue;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  padding: 0.2rem 0.7rem;
  //padding-bottom: 0.1rem;
  font-weight: 800;
  border-radius: 10px;
  font-size: 0.8rem;
  font-family: $family-button !important;
}

.pageTile__line__style {
  width: 40%;
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding-bottom: 0.5rem;
  border-top-color: $color-red;
  &.red {
    border-top-color: $color-red;
  }
  &.orange {
    border-top-color: $color-orange;
  }
  &.yellow {
    border-top-color: $color-yellow;
  }
  &.green {
    border-top-color: $color-green;
  }
  &.aqua {
    border-top-color: $color-aqua;
  }
  &.blue {
    border-top-color: $color-blue;
  }
  &.purple {
    border-top-color: $color-purple;
  }
  &.violet {
    border-top-color: $color-violet;
  }
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 0.5rem 2.5rem;
  text-align: center;
  background-color: white;
  margin-top: -1px;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(
      to bottom left,
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }

  .pageTile__title,
  .pageTile_desc {
    color: $color-dark-gray2 !important;
  }
}
.contentPanel--white {
  .pageTile__title,
  .pageTile_desc {
    color: black;
  }
}
.pageTile.tile {
  padding-left: 15px;
  padding-right: 15px;

  &.red {
    .pageTile__title,
    .pageTile_desc {
      color: $color-red;
    }
    .pageTile_read_more {
      background-color: $color-red;
      //border: 1px solid $color-red;
    }
  }
  &.orange {
    .pageTile__title,
    .pageTile_desc {
      color: $color-orange;
    }
    .pageTile_read_more {
      background-color: $color-orange;
    }
  }
  &.yellow {
    .pageTile__title,
    .pageTile_desc {
      color: $color-yellow;
    }
    .pageTile_read_more {
      background-color: $color-yellow;
    }
  }
  &.green {
    .pageTile__title,
    .pageTile_desc {
      color: $color-green;
    }
    .pageTile_read_more {
      background-color: $color-green;
    }
  }
  &.aqua {
    .pageTile__title,
    .pageTile_desc {
      color: $color-aqua;
    }
    .pageTile_read_more {
      background-color: $color-aqua;
    }
  }
  &.blue {
    .pageTile__title,
    .pageTile_desc {
      color: $color-blue;
    }
    .pageTile_read_more {
      background-color: $color-blue;
    }
  }
  &.purple {
    .pageTile__title,
    .pageTile_desc {
      color: $color-purple;
    }
    .pageTile_read_more {
      background-color: $color-purple;
    }
  }
  &.violet {
    .pageTile__title,
    .pageTile_desc {
      color: $color-violet;
    }
    .pageTile_read_more {
      background-color: $color-violet;
    }
  }
}

// Edithburghcp pageTile
.pageTile__bsyc__content:hover .tile_btn_animation::after {
  visibility: visible;
  transform: scale(100) translateX(2px);
}
.c-button {
  color: #13607b;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  border: 0;
  background: transparent;
  padding: 0.6rem 1rem;
}
.c-button--gooey {
  position: relative;
  transition: all 700ms ease;
}
.c-button--gooey .c-button__blobs {
  height: 100%;
  filter: url(#goo);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -3px;
  right: -1px;
  z-index: -1;
}
.c-button--gooey .c-button__blobs div {
  background-color: #13607b;
  width: 34%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  transform: scale(1.4) translateY(125%) translateZ(0);
  transition: all 700ms ease;
  @media screen and (max-width: 576px) {
    width: 50%;
  }
}
.c-button--gooey .c-button__blobs div:nth-child(1) {
  left: -5%;
}
.c-button--gooey .c-button__blobs div:nth-child(2) {
  left: 30%;
  transition-delay: 60ms;
}
.c-button--gooey .c-button__blobs div:nth-child(3) {
  left: 66%;
  transition-delay: 25ms;
}
.c-button--gooey:hover {
  color: #fff;
}
.c-button--gooey:hover .c-button__blobs div {
  transform: scale(1.4) translateY(0) translateZ(0);
}
